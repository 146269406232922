import React from "react";

import Modal from "../../../../../components/helpers/Modal/Modal";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { FormField } from "../../../../../components/form/FormField/FormField";

import style from "./PCRSAddProgrammeDialog.module.css";
import Select from "../../../../../components/helpers/Select/Select";

const formSchema = z.object({
  program: z.string().min(1, { message: "Please provide program." }),
});

export const PCRSAddProgrammeDialog = ({
  isPcrsAddProgrammeDialogOpen,
  setIsPcrsAddProgrammeDialogOpen,
  onAddOcfButtonClick,
  onAddPpButtonClick,
  onAddCdmButtonClick,
}) => {
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      program: "Chronic Disease Management",
    },
  });

  const handleFormSubmit = (data) => {
    setIsPcrsAddProgrammeDialogOpen(false);
    if (data.program === "Chronic Disease Management") {
      onAddCdmButtonClick();
      return;
    }

    if (data.program === "Prevention Programme") {
      onAddPpButtonClick();
      return;
    }

    onAddOcfButtonClick();
  };

  if (!isPcrsAddProgrammeDialogOpen) {
    return null;
  }

  return (
    <Modal
      title="CDM Programme"
      position="center"
      onClose={() => setIsPcrsAddProgrammeDialogOpen(false)}
      hideCancel={true}
      width="540px"
    >
      <FormProvider {...form}>
        <form
          className={style.addReferralForm}
          onSubmit={form.handleSubmit(handleFormSubmit)}
        >
          <FormField name="contractDoctor">
            <label className={style.addReferralFormLabel}>Programme</label>
            <Select
              options={[
                "Opportunistic Case Finding",
                "Prevention Programme",
                "Chronic Disease Management",
              ]}
              value={form.watch("program") ?? ""}
              onChange={(value) => form.setValue("program", value)}
              required
            />
          </FormField>

          <div className={style.bottomSection}>
            <button
              type="button"
              className={style.cancelButton}
              onClick={() => setIsPcrsAddProgrammeDialogOpen(false)}
            >
              Cancel
            </button>
            <button type="submit" className={style.sendButton}>
              Continue
            </button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

import React from "react";

import Modal from "../../../../../components/helpers/Modal/Modal";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { FormField } from "../../../../../components/form/FormField/FormField";

import style from "./PCRSLoginDialog.module.css";

const formSchema = z.object({
  username: z.string().min(1, { message: "Please provide username." }),
  password: z.string().min(1, { message: "Please provide password." }),
});

export const PCRSLoginDialog = ({
  isPcrsLoginDialogOpen,
  setIsPcrsLoginDialogOpen,
  onSuccess,
}) => {
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const handleFormSubmit = (data) => {
    console.log(data);
    setIsPcrsLoginDialogOpen(false);
    onSuccess();
  };

  if (!isPcrsLoginDialogOpen) {
    return null;
  }

  return (
    <Modal
      title="PCRS Login"
      position="center"
      onClose={() => setIsPcrsLoginDialogOpen(false)}
      hideCancel={true}
      width="540px"
    >
      <FormProvider {...form}>
        <form
          className={style.addReferralForm}
          onSubmit={form.handleSubmit(handleFormSubmit)}
        >
          <FormField name="username">
            <label className={style.addReferralFormLabel}>Username</label>
            <input className="form-control" {...form.register("username")} />
          </FormField>

          <FormField name="password">
            <label className={style.addReferralFormLabel}>Password</label>
            <input className="form-control" {...form.register("password")} />
          </FormField>

          <div className={style.bottomSection}>
            <button
              type="button"
              className={style.cancelButton}
              onClick={() => setIsPcrsLoginDialogOpen(false)}
            >
              Cancel
            </button>
            <button type="submit" className={style.sendButton}>
              Login
            </button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import { createAxiosInstance } from "../../../api/axiosConfig";
import { useLocation } from "react-router-dom";
import {
  GET_APPOINTMENTS_HOME_PAGE,
  PATIENT_CONTROLER_URL_ID,
  WHO_AM_I,
  PRACTICE_SERVICE_CONTROLER_URL,
  PRACTICE_HOURS_CONTROLER_URL,
} from "../../../api/Service";
import { useDispatch, useSelector } from "react-redux";
import { logoutFromHelper } from "../../../api/Helper";
import { logout } from "../../../redux/auth/AuthSlice";
import { removeuser } from "../../../redux/user/userSlice";
import { useNavigate } from "react-router-dom";
import AddAppointmentModal from "../../../components/Modal/AddAppointmentModal";
import { Tasks } from "./components/Tasks";
import PatientInformation from "./components/PatientInformation/PatientInformation";
import s from "./AlliedHome.module.css";
import HorizontalCalendar from "./components/HorizontalCalendar/HorizontalCalendar";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TextField } from "@mui/material";
import TimeTable from "./components/TimeTable/TimeTable";

const AlliedHome = () => {
  const [selectedDate, setSelectedDate] = useState([]);
  const [calenderDate, setcalenderDate] = useState(moment().date());
  const [calenderMonth, setcalenderMonth] = useState(moment().month() + 1);
  const [calenderYear, setcalenderYear] = useState(moment().year());
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [consultationLength, setConsultationLength] = useState(0);
  const [freeTime, setFreeTime] = useState(0);
  const [selectedDateForAppointment, setSelectedDateForAppointment] = useState(
    new Date()
  );
  const [staffMemberId, setStaffMemberId] = useState();
  const [practiceServices, setPracticeServices] = useState([]);
  const [startDateForAppointment, setStartDateForAppointment] = useState();
  const [startTimeForAppointment, setStartTimeForAppointment] = useState();

  const [allDayStartTimeAndEndTime, setAllDayStartTimeAndEndTime] = useState(
    []
  );

  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const [indexAndPatient, setIndexAndPatient] = useState({});

  const [x, setX] = useState({
    slotInterval: 15,
    openTime: "",
    closeTime: "",
  });
  const [allTimes, setAllTimes] = useState([]);
  const swiperRef = useRef(null);

  const fetchHours = async () => {
    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${PRACTICE_HOURS_CONTROLER_URL}`
      );
      setAllDayStartTimeAndEndTime(response.data);
    } catch (error) {
      errorHandling(error);
    }
  };

  const updateSlots = async () => {
    if (allDayStartTimeAndEndTime) {
      let ans = allDayStartTimeAndEndTime.filter(
        (f) =>
          f.day &&
          f.day.toLowerCase() ===
            moment(selectedDate).format("dddd").toLowerCase()
      )[0];

      if (ans && ans.startTime && ans.endTime) {
        let oTime = moment("Sat Mar 02 2024 " + ans.startTime).format(
          "HH:mm A"
        );
        let cTime = moment("Sat Mar 02 2024 " + ans.endTime).format("HH:mm A");
        setX({
          slotInterval: 15,
          openTime: oTime,
          closeTime: cTime,
        });
        const startTime = moment(oTime, "h:mm A");
        const endTime = moment(cTime, "h:mm A");
        let allTimesAli = [];
        while (startTime <= endTime) {
          allTimesAli.push(startTime.format("HH:mm"));
          startTime.add(x.slotInterval, "minutes");
        }
        setAllTimes(allTimesAli);
      }
    }
  };

  const fetchProfileInfo = async () => {
    try {
      const axiosInstance = createAxiosInstance(true);
      const response = await axiosInstance.get(`${WHO_AM_I}`);
      if (
        response &&
        response.data &&
        response.data.staffMember &&
        response.data.staffMember.id
      ) {
        setStaffMemberId(
          response && response.data && response.data.staffMember
            ? response.data.staffMember.id
            : ""
        );
      }
    } catch (error) {
      errorHandling(error);
    }
  };

  const fetchPracticeServices = async () => {
    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${PRACTICE_SERVICE_CONTROLER_URL}`
      );
      let t = [{ label: "Type of Consultation*", value: "" }];
      if (response && response.data) {
        response.data.forEach((element) => {
          t.push({ label: element.name, value: element.id });
        });
      }

      setPracticeServices(t);
    } catch (error) {
      errorHandling(error);
    }
  };

  let tenantVal = useSelector((state) => state.auth.user?.tenant);
  if (!tenantVal) tenantVal = localStorage.getItem("kinesin-tenant");

  const getPatientById = async (id, reasonForVisit) => {
    if (!id) return;

    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${PATIENT_CONTROLER_URL_ID}${id}`
      );
      if (response && response.data) {
        response.data.reasonForVisit = reasonForVisit;
        setSelectedPatient(response.data);
      }
    } catch (error) {
      errorHandling(error);
    }
  };

  const getByStaffMemberId = async (date) => {
    if (!date || !x.openTime) return;

    var startTimeApt = moment(date).format("yyyy-MM-DD");
    var endTimeApt = moment(date).format("yyyy-MM-DD");
    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${GET_APPOINTMENTS_HOME_PAGE}` +
          "?startDate=" +
          startTimeApt +
          "&endDate=" +
          endTimeApt
      );

      let dddd = new Date(date);
      dddd.setHours(moment("Sat Mar 02 2024 " + x.openTime).format("HH"));
      dddd.setMinutes(moment("Sat Mar 02 2024 " + x.openTime).format("mm"));
      dddd.setSeconds(0);
      let finalResponse = [];

      if (response.data) {
        response.data.forEach((d) => {
          if (
            d.startTime &&
            moment(moment(d.startTime).format("yyyy-MM-DD")).isSame(
              moment(moment(dddd).format("yyyy-MM-DD"))
            )
          ) {
            if (moment(d.startTime).isSameOrAfter(moment(dddd)))
              finalResponse.push(d);
          }
        });
      }

      setConsultationLength(finalResponse ? finalResponse.length : 0);

      const sTime = moment(x.openTime, "h:mm A");
      const eTime = moment(x.closeTime, "h:mm A");
      let patientArray = [];

      let num = 0;
      let indexAndPatient = {};
      while (sTime <= eTime) {
        let p;
        if (finalResponse && finalResponse.length > 0) {
          p = finalResponse.filter(
            (pt) =>
              moment(pt.startTime).format("HH:mm") === sTime.format("HH:mm")
          );
          if (p && p[0]) {
            patientArray.push(p[0]);
            if (p[0].durationInMins === 15) num = 0;
            else if (p[0].durationInMins === 30) num = 1;
            else if (p[0].durationInMins === 45) num = 2;
            else if (p[0].durationInMins === 60) num = 3;

            p[0].isDisplay = true;
            indexAndPatient[sTime.format("HH:mm")] = p[0];
          } else {
            if (num === 0)
              indexAndPatient[sTime.format("HH:mm")] = { isDisplay: true };
            else {
              indexAndPatient[sTime.format("HH:mm")] = { isDisplay: false };
              num--;
            }
          }
        } else {
          indexAndPatient[sTime.format("HH:mm")] = { isDisplay: true };
        }
        sTime.add(x.slotInterval, "minutes");
        setStartTimeForAppointment();
      }
      setIndexAndPatient(indexAndPatient);

      let countFreeTime = 0;
      if (indexAndPatient) {
        for (const [key, value] of Object.entries(indexAndPatient)) {
          if (value && value.isDisplay && !value.durationInMins)
            countFreeTime++;
        }
      }
      setFreeTime(countFreeTime);
      if (patientArray && patientArray.length > 0) {
        let list;
        for (let i = 0; i < patientArray.length; i++) {
          if (
            patientArray[i] &&
            patientArray[i].startTime &&
            new moment(new Date()).isBefore(moment(patientArray[i].startTime))
          ) {
            list = patientArray[i];
            break;
          }
        }

        if (list && list.patient && list.patient.id) {
          getPatientById(
            list && list.patient ? list.patient.id : "",
            list && list.reasonForVisit ? list.reasonForVisit : ""
          );
          setSelectedAppointment(list);
        } else {
          setSelectedPatient(null);
        }
      }
    } catch (error) {
      errorHandling(error);
    }
  };
  useEffect(() => {
    fetchHours();
    fetchProfileInfo();
    fetchPracticeServices();
  }, [tenantVal]);

  useEffect(() => {
    fetchHours();
    updateSlots();
    const swiperInstance = swiperRef.current?.swiper;

    if (swiperInstance) {
      swiperInstance.slideTo(calenderDate - 1);
    }
  }, [calenderDate, calenderMonth, calenderYear]);

  useEffect(() => {
    const formattedDate = new Date(
      calenderYear,
      calenderMonth - 1,
      calenderDate,
      18,
      30,
      0
    ).toISOString();
    // Updating the selectedDate state
    setSelectedDate(formattedDate);
  }, [calenderDate, calenderYear, calenderMonth]);

  useEffect(() => {
    if (state && state.showToast) {
      // Display toast message
      toast.success("Profile updated successfully.");
    }
  }, [state]);

  useEffect(() => {
    if (selectedDate) {
      getByStaffMemberId(selectedDate);
    }
  }, [x]);

  useEffect(() => {
    updateSlots();
  }, [allDayStartTimeAndEndTime]);

  const errorHandling = (error) => {
    if (error && error.response) {
      if (error.response.data && error.response.data.message)
        toast.error(error.response.data.message);

      if (error.response.status && error.response.status == 403) {
        logoutFromHelper();
        dispatch(logout());
        dispatch(removeuser());
        navigate("/logout");
      }
    }
  };

  const displayAge = (dob) => {
    if (!dob) return "";

    let aa = dob.split("-");
    if (aa && aa.length == 3) {
      return aa[2] + "/" + aa[1] + "/" + aa[0];
    } else {
      return dob;
    }
  };

  // Component to count the words from notes and show remaining words or text into tooltip
  const ParagraphWithHover = ({ text }) => {
    const [showFullText, setShowFullText] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const words = text.split(" ");

    const wordLimit = 15;
    const firstTwoLinesWords = words.slice(0, wordLimit);
    const remainingWords = words.slice(wordLimit);

    // Function to handle hover and show tooltip
    const handleMouseOver = () => {
      setShowTooltip(true);
    };

    // Function to handle mouse leave and hide tooltip
    const handleMouseLeave = () => {
      setShowTooltip(false);
    };
    return (
      <h6 onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave}>
        {showFullText ? (
          text
        ) : (
          <>
            {firstTwoLinesWords.join(" ")}
            {remainingWords.length > 0 && "..."}
            {showTooltip && <span className="hover-text">{text}</span>}
          </>
        )}
      </h6>
    );
  };

  return (
    <>
      <div className="dashBcontbody alliedhome">
        <button
          id="idButtonAddAppointmentPopup"
          data-bs-toggle="modal"
          data-bs-target="#addAppointment_modal"
          style={{
            height: 0,
            padding: 0,
            borderRightWidth: 0,
            borderLeftWidth: 0,
          }}
        />
        <Toaster position="top-center" reverseOrder={false} />
        <div className={s.wrapper}>
          <div className={s.appointmentsPatientContainer}>
            <div className={s.appointmentsContainer}>
              <div className={s.summaryHeading}>
                <h2 className={s.summaryTitle}>My Appointments</h2>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    views={["month", "year"]}
                    value={moment(`${calenderYear}-${calenderMonth}-01`)}
                    onChange={(newValue) => {
                      setcalenderMonth(moment(newValue).month() + 1);
                      setcalenderYear(moment(newValue).year());
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "12px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#006546",
                        },
                      },
                    }}
                    slotProps={{
                      desktopPaper: {
                        sx: {
                          borderRadius: "12px",
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div className={s.appointmentsContent}>
                <div className={s.calendarWrapper}>
                  <HorizontalCalendar
                    selectedDate={calenderDate}
                    selectedMonth={calenderMonth}
                    selectedYear={calenderYear}
                    setSelectedDate={setcalenderDate}
                    setSelectedMonth={setcalenderMonth}
                    setSelectedYear={setcalenderYear}
                  />
                </div>

                <div className={s.horizontalDivider} />

                <TimeTable
                  allTimes={allTimes}
                  indexAndPatient={indexAndPatient}
                  refetchAppointments={() => getByStaffMemberId(selectedDate)}
                  getPatientById={getPatientById}
                  selectedDate={selectedDate}
                  selectedAppointment={selectedAppointment}
                  setSelectedAppointment={setSelectedAppointment}
                  setStartDateForAppointment={setStartDateForAppointment}
                  setStartTimeForAppointment={setStartTimeForAppointment}
                  setSelectedDateForAppointment={setSelectedDateForAppointment}
                  calendarDate={calenderDate}
                  calendarMonth={calenderMonth}
                  calendarYear={calenderYear}
                  freeTime={freeTime}
                  consultationLength={consultationLength}
                />
              </div>
            </div>

            <div className={s.divider} />

            <div>
              <div className={s.summaryHeading}>
                <h2 className={s.summaryTitle}>Patient Information</h2>
              </div>
              {selectedPatient && (
                <PatientInformation
                  selectedPatient={selectedPatient}
                  selected={selectedAppointment}
                />
              )}
            </div>

            <div className={s.divider} />

            <Tasks />
          </div>
        </div>

        <div className="versionData">Kinesin version: 1.0-321-2025-04-02</div>
      </div>

      <AddAppointmentModal
        headerTitle={"Add Appointment"}
        selectedDate={selectedDateForAppointment}
        staffId={staffMemberId}
        setStaffId={setStaffMemberId}
        practiceServices={practiceServices}
        startDate={startDateForAppointment}
        startTime={startTimeForAppointment}
        selectedView="day"
        homePageRefreshAppointment={getByStaffMemberId}
      />
    </>
  );
};

export default AlliedHome;

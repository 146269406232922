import React from "react";
import { BiChevronDown } from "react-icons/bi";
import s from "./Select.module.css";

const Select = ({
  options,
  value,
  onChange,
  small = false,
  disabled = false,
}) => {
  const handleChange = (event) => {
    event.persist();
    onChange(event.target.value);
  };

  console.log(disabled);

  if (options.at(0)?.value && options.at(0)?.label) {
    return (
      <div
        className={s.container}
        style={{ height: small ? "36px" : "48px" }}
        disabled={disabled}
      >
        <select
          className={s.select}
          style={{
            height: small ? "36px" : "48px",
            padding: small ? "4px 12px" : "12px 20px",
          }}
          value={value}
          onChange={handleChange}
          disabled={disabled}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className={s.icon}>
          <BiChevronDown />
        </div>
      </div>
    );
  }

  return (
    <div className={s.container} style={{ height: small ? "36px" : "48px" }}>
      <select
        className={s.select}
        style={{
          height: small ? "36px" : "48px",
          padding: small ? "4px 12px" : "12px 20px",
        }}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div className={s.icon}>
        <BiChevronDown />
      </div>
    </div>
  );
};

export default Select;

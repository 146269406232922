import React, { useState } from "react";
import { useSelector } from "react-redux";

import s from "./PatientCDM.module.css";
import NavigationBar from "../../PatientDetailGp/components/NavigationBar/NavigationBar.jsx";
import PatientCard from "../PatientCard/PatientCard.jsx";
import Modal from "../../../../components/helpers/Modal/Modal.jsx";
import { CdmSidebar } from "./CdmSidebar/CdmSidebar.jsx";
import { useQuery } from "@tanstack/react-query";
import { fetchPatientById } from "./utils/fetchPatientById.js";
import { useView, views } from "./hooks/useView.js";
import { PregnancyLoader } from "./components/PregnancyLoader/PregnancyLoader.jsx";
import { fetchChronicDiseasesByPatientId } from "./utils/fetchChronicDiseasesByPatientId.js";
import { PCRSLoginDialog } from "./PCRSLoginDialog/PCRSLoginDialog.jsx";
import { PCRSImportDialog } from "./PCRSImportDialog/PCRSImportDialog.jsx";
import { PCRSAddProgrammeDialog } from "./PCRSAddProgrammeDialog/PCRSAddProgrammeDialog.jsx";
import { AddOCFReviewDialog } from "./AddOCFReviewDialog/AddOCFReviewDialog.jsx";
import { AddPPReviewDialog } from "./AddPPReviewDialog/AddPPReviewDialog.jsx";
import { AddCDMReviewDialog } from "./AddCDMReviewDialog/AddCDMReviewDialog.jsx";
import { NoCdmSection } from "./NoCdmSection/NoCdmSection.jsx";

const PatientCDM = () => {
  const patientId = localStorage.getItem("kinesin-patientId");
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const { isLoading, data: patient } = useQuery({
    queryKey: ["patient", patientId, tenantVal],
    queryFn: () => fetchPatientById(patientId, tenantVal),
  });

  const { isLoading: isCdmLoading, data: cdmData } = useQuery({
    queryKey: ["chronicDiseases", patientId, tenantVal],
    queryFn: () => fetchChronicDiseasesByPatientId(patientId, tenantVal),
  });

  const [isPcrsLoginDialogOpen, setIsPcrsLoginDialogOpen] = useState(false);
  const [isPcrsImportDialogOpen, setIsPcrsImportDialogOpen] = useState(false);
  const [isAddOcfReviewDialogOpen, setIsAddOcfReviewDialogOpen] =
    useState(false);
  const [isAddPpReviewDialogOpen, setIsAddPpReviewDialogOpen] = useState(false);
  const [isAddCdmReviewDialogOpen, setIsAddCdmReviewDialogOpen] =
    useState(false);
  const [isPcrsAddProgrammeDialogOpen, setIsPcrsAddProgrammeDialogOpen] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState({ open: false, id: null });
  const [view, setView] = useView();

  if (isCdmLoading) {
    return <PregnancyLoader>Loading Chronic Diseases...</PregnancyLoader>;
  }

  return (
    <>
      <NavigationBar />
      <div style={{ margin: "0 auto" }}>
        <div className={s.wrapper}>
          <div className={s.firstColumnWrapper}>
            {isLoading ? (
              <p>Patient Chronic diseases is loading...</p>
            ) : (
              <>
                <PatientCard patient={patient} />
                <CdmSidebar
                  setView={setView}
                  cdms={cdmData}
                  onAddCdmButtonClick={() => setIsModalOpen({ open: true })}
                  view={view}
                />
              </>
            )}
          </div>
          <div className={s.secondColumnWrapper}>
            <div className={s.secondColumnSubWrapper}>
              {view.name === views.noChronicDiseases && (
                <NoCdmSection
                  onAddCdmButtonClick={() => setIsPcrsLoginDialogOpen(true)}
                />
              )}
              {view.name === views.general && <p>general</p>}
              {/* {view.name === views.anteNatal && <p>anteNatal</p>}
              {view.name === views.postNatal && <p>postNatal</p>} */}
            </div>
          </div>
        </div>
        {isModalOpen.open && (
          <Modal
            title="Add new review"
            position="center"
            onClose={() => setIsModalOpen({ open: false })}
            hideCancel={true}
            width="800px"
          >
            <p>Add new review</p>
          </Modal>
        )}
        <PCRSLoginDialog
          isPcrsLoginDialogOpen={isPcrsLoginDialogOpen}
          setIsPcrsLoginDialogOpen={setIsPcrsLoginDialogOpen}
          onSuccess={() => setIsPcrsImportDialogOpen(true)}
        />
        <PCRSImportDialog
          isPcrsImportDialogOpen={isPcrsImportDialogOpen}
          setIsPcrsImportDialogOpen={setIsPcrsImportDialogOpen}
          onSuccess={() => setIsPcrsAddProgrammeDialogOpen(true)}
        />
        <PCRSAddProgrammeDialog
          isPcrsAddProgrammeDialogOpen={isPcrsAddProgrammeDialogOpen}
          setIsPcrsAddProgrammeDialogOpen={setIsPcrsAddProgrammeDialogOpen}
          onAddOcfButtonClick={() => setIsAddOcfReviewDialogOpen(true)}
          onAddPpButtonClick={() => setIsAddPpReviewDialogOpen(true)}
          onAddCdmButtonClick={() => setIsAddCdmReviewDialogOpen(true)}
        />
        <AddOCFReviewDialog
          isAddOcfReviewDialogOpen={isAddOcfReviewDialogOpen}
          setIsAddOcfReviewDialogOpen={setIsAddOcfReviewDialogOpen}
        />
        <AddPPReviewDialog
          isAddPpReviewDialogOpen={isAddPpReviewDialogOpen}
          setIsAddPpReviewDialogOpen={setIsAddPpReviewDialogOpen}
        />
        <AddCDMReviewDialog
          isAddCdmReviewDialogOpen={isAddCdmReviewDialogOpen}
          setIsAddCdmReviewDialogOpen={setIsAddCdmReviewDialogOpen}
        />
      </div>
    </>
  );
};

export default PatientCDM;

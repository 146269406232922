import React from "react";

import Modal from "../../../../../components/helpers/Modal/Modal";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { format, getYear } from "date-fns";

import style from "./AddPPReviewDialog.module.css";
import { FormField } from "../../../../../components/form/FormField/FormField";
import Select from "../../../../../components/helpers/Select/Select";
import { useStaffMembers } from "../../../../../hooks/useStaffMembers";
import CustomCheckbox from "../../../../../components/helpers/CustomCheckbox/CustomCheckbox";

const formSchema = z.object({
  eircode: z.string().min(1, { message: "Please provide eircode." }),
  ethnicity: z.string().min(1, { message: "Please provide ethnicity." }),
  reviewingDoctor: z
    .string()
    .min(1, { message: "Please provide reviewing doctor." }),

  qrisk3: z.boolean().optional(),
  hypertenstionStage1: z.boolean().optional(),
  hypertenstionStage2: z.boolean().optional(),
  preDiabetes: z.boolean().optional(),
  previousBNP: z.boolean().optional(),

  qrisk3Year: z.string().optional(),
  hypertenstionStage1Year: z.string().optional(),
  hypertenstionStage2Year: z.string().optional(),
  preDiabetesYear: z.string().optional(),
  previousBNPYear: z.string().optional(),
});

const diagnosisItems = [
  {
    id: "qrisk3",
    name: "QRISK 3>=20%",
  },
  {
    id: "hypertenstionStage1",
    name: "Hypertenstion Stage 1 (BP 140/90 to 159/99mmHg) with target organ damage",
  },
  {
    id: "hypertenstionStage2",
    name: "Hypertension Stage 2 or 3 (BP>160/100mmHg)",
  },
  {
    id: "preDiabetes",
    name: "Pre-Diabetes",
  },
  {
    id: "previousBNP",
    name: "Previous BNP >=34pg/ml or NT PRO BNP >= 125pg/ml, if previously recorded",
  },
];

export const AddPPReviewDialog = ({
  isAddPpReviewDialogOpen,
  setIsAddPpReviewDialogOpen,
}) => {
  const { data: staffMembers } = useStaffMembers();
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      eircode: "",
      contractDoctor: "",
    },
  });

  const handleFormSubmit = (data) => {
    console.log(data);
    setIsAddPpReviewDialogOpen(false);
  };

  const importedData = {
    gmsNumber: "423423423",
    contractDoctor: "Dr. John Doe",
    contractDoctorHash: "545345345",
    patientDOB: "13/03/1980",
  };

  if (!isAddPpReviewDialogOpen) {
    return null;
  }

  return (
    <Modal
      title="Add Review"
      position="center"
      onClose={() => setIsAddPpReviewDialogOpen(false)}
      hideCancel={true}
      width="1100px"
      maxHeight="95vh"
    >
      <div style={{ overflowY: "auto", maxHeight: "calc(95vh - 140px)" }}>
        <div className={style.importedDataWrapper}>
          <div className={style.importedDataItem}>
            <p className={style.importedDataItemLabel}>GMS Number</p>
            <p className={style.importedDataItemValue}>
              {importedData.gmsNumber}
            </p>
          </div>
          <div className={style.importedDataItem}>
            <p className={style.importedDataItemLabel}>Contract Doctor</p>
            <p className={style.importedDataItemValue}>
              {importedData.contractDoctor}
            </p>
          </div>
          <div className={style.importedDataItem}>
            <p className={style.importedDataItemLabel}>Contract Doctor #</p>
            <p className={style.importedDataItemValue}>
              {importedData.contractDoctorHash}
            </p>
          </div>
          <div className={style.importedDataItem}>
            <p className={style.importedDataItemLabel}>Patient DOB</p>
            <p className={style.importedDataItemValue}>
              {importedData.patientDOB}
            </p>
          </div>
        </div>
        <FormProvider {...form}>
          <form
            className={style.addReferralForm}
            onSubmit={form.handleSubmit(handleFormSubmit)}
          >
            <div className={style.inputGroupGrid4Columns}>
              <div className={`${style.formField} form-field-controll`}>
                <div>
                  <label className={style.pregnancyFormLabel}>
                    Consultation date
                  </label>
                  <input
                    name="consultationDate"
                    disabled
                    type="text"
                    className="form-control"
                    value={format(new Date(), "dd/MM/yyyy")}
                  />
                </div>
              </div>
              <FormField name="contractDoctor">
                <label className={style.addReferralFormLabel}>
                  Contract Doctor
                </label>
                <Select
                  options={staffMembers.map((staff) => ({
                    value: staff.id,
                    label: `${staff.firstName} ${staff.lastName}`,
                  }))}
                  value={form.watch("reviewingDoctor") ?? ""}
                  onChange={(value) => form.setValue("reviewingDoctor", value)}
                  required
                />
              </FormField>
              <FormField name="ethnicity">
                <label className={style.addReferralFormLabel}>Ethnicity</label>
                <Select
                  options={[
                    "White Irish",
                    "White Other",
                    "Asian",
                    "Black",
                    "Other",
                  ]}
                  value={form.watch("ethnicity") ?? ""}
                  onChange={(value) => form.setValue("ethnicity", value)}
                  required
                />
              </FormField>
              <FormField name="eircode">
                <label className={style.pregnancyFormLabel}>Eircode</label>
                <input
                  type="text"
                  className="form-control"
                  {...form.register("eircode")}
                />
              </FormField>
            </div>
            <div className={style.indicatorsSection}>
              <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>
                Diagnosis
              </h2>
              <div className={style.tableContainer}>
                <table className={style.table}>
                  <thead className={style.thead}>
                    <tr>
                      <th className={style.th}></th>
                      <th className={style.th}>Reason</th>
                      <th className={style.th}>Status</th>
                      <th className={style.th}>Last Review</th>
                      <th className={style.th}>Year</th>
                    </tr>
                  </thead>
                  <tbody>
                    {diagnosisItems.map((item) => (
                      <tr key={item.id}>
                        <td
                          className={style.tdName}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "40px",
                          }}
                        >
                          <CustomCheckbox {...form.register(item.id)} />
                        </td>
                        <td
                          title="Diagnosis"
                          className={style.tdName}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "300px",
                          }}
                        >
                          {item.name}
                        </td>
                        <td
                          title="Status"
                          className={style.tdName}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "120px",
                          }}
                        ></td>
                        <td
                          title="Last Review"
                          className={style.tdName}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "120px",
                          }}
                        ></td>
                        <td
                          title="Year"
                          className={style.tdName}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "140px",
                          }}
                        >
                          <Select
                            small
                            options={Array.from(
                              { length: 150 },
                              (_, i) => getYear(new Date()) - i
                            ).map((year) => ({
                              value: year,
                              label: year,
                            }))}
                            disabled={!form.watch(item.id)}
                            value={form.watch(`${item.id}Year`) ?? ""}
                            onChange={(value) =>
                              form.setValue(`${item.id}Year`, value)
                            }
                            required
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className={style.bottomSection}>
              <button
                type="button"
                className={style.cancelButton}
                onClick={() => setIsAddPpReviewDialogOpen(false)}
              >
                Cancel
              </button>
              <button type="submit" className={style.sendButton}>
                Confirm
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

import React, { useEffect } from "react";

import Modal from "../../../../../components/helpers/Modal/Modal";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { FormField } from "../../../../../components/form/FormField/FormField";

import style from "./PCRSImportDialog.module.css";
import Select from "../../../../../components/helpers/Select/Select";
import { useStaffMembers } from "../../../../../hooks/useStaffMembers";

const formSchema = z.object({
  contractDoctor: z
    .string()
    .min(1, { message: "Please provide contract doctor." }),
});

export const PCRSImportDialog = ({
  isPcrsImportDialogOpen,
  setIsPcrsImportDialogOpen,
  onSuccess,
}) => {
  const { data: staffMembers } = useStaffMembers();
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      contractDoctor: staffMembers?.at(0)?.id,
    },
  });
  useEffect(() => {
    if (staffMembers?.at(0)?.id) {
      form.setValue("contractDoctor", staffMembers?.at(0)?.id?.toString());
    }
  }, [staffMembers, form]);

  const handleFormSubmit = (data) => {
    console.log(data);
    setIsPcrsImportDialogOpen(false);
    onSuccess();
  };

  const importedData = {
    currentProgramme: "Pard OCF Review",
    paidOCFReview: "lorem ipsum",
    registrationDate: "2025/01/01",
    pardPPPREview: "lorem ipsum",
    lastReview: "12/10/2024",
    lastNumberOfConditions: 2,
    cardNumber: "1234567890",
    gpNumber: "1234567890",
    dob: "15/10/1980",
  };

  if (!isPcrsImportDialogOpen) {
    return null;
  }

  return (
    <Modal
      title="Import Programme Registration"
      position="center"
      onClose={() => setIsPcrsImportDialogOpen(false)}
      hideCancel={true}
      width="800px"
    >
      <div className={style.importedDataWrapper}>
        <div className={style.importedDataItem}>
          <p className={style.importedDataItemLabel}>Current Programme</p>
          <p className={style.importedDataItemValue}>
            {importedData.currentProgramme}
          </p>
        </div>
        <div className={style.importedDataItem}>
          <p className={style.importedDataItemLabel}>Paid OCF Review</p>
          <p className={style.importedDataItemValue}>
            {importedData.paidOCFReview}
          </p>
        </div>
        <div className={style.importedDataItem}>
          <p className={style.importedDataItemLabel}>Registration Date</p>
          <p className={style.importedDataItemValue}>
            {importedData.registrationDate}
          </p>
        </div>
        <div className={style.importedDataItem}>
          <p className={style.importedDataItemLabel}>Pard PPP Review</p>
          <p className={style.importedDataItemValue}>
            {importedData.pardPPPREview}
          </p>
        </div>
        <div className={style.importedDataItem}>
          <p className={style.importedDataItemLabel}>Last Review(s)</p>
          <p className={style.importedDataItemValue}>
            {importedData.lastReview}
          </p>
        </div>
        <div className={style.importedDataItem}>
          <p className={style.importedDataItemLabel}>
            Last Number of Conditions
          </p>
          <p className={style.importedDataItemValue}>
            {importedData.lastNumberOfConditions}
          </p>
        </div>
        <div className={style.importedDataItem}>
          <p className={style.importedDataItemLabel}>Card Number</p>
          <p className={style.importedDataItemValue}>
            {importedData.cardNumber}
          </p>
        </div>
        <div className={style.importedDataItem}>
          <p className={style.importedDataItemLabel}>GP Number</p>
          <p className={style.importedDataItemValue}>{importedData.gpNumber}</p>
        </div>
        <div className={style.importedDataItem}>
          <p className={style.importedDataItemLabel}>DOB</p>
          <p className={style.importedDataItemValue}>{importedData.dob}</p>
        </div>
      </div>
      <FormProvider {...form}>
        <form
          className={style.addReferralForm}
          onSubmit={form.handleSubmit(handleFormSubmit)}
        >
          <FormField name="contractDoctor">
            <label className={style.addReferralFormLabel}>
              Contract Doctor
            </label>
            <Select
              options={staffMembers.map((staff) => ({
                value: staff.id,
                label: `${staff.firstName} ${staff.lastName}`,
              }))}
              value={form.watch("contractDoctor") ?? staffMembers?.at(0)?.id}
              onChange={(value) => form.setValue("contractDoctor", value)}
              required
            />
          </FormField>

          <div className={style.bottomSection}>
            <button
              type="button"
              className={style.cancelButton}
              onClick={() => setIsPcrsImportDialogOpen(false)}
            >
              Cancel
            </button>
            <button type="submit" className={style.sendButton}>
              Import
            </button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
};

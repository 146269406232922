import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { createAxiosInstance } from "../api/axiosConfig";

const useHealthMailLoginUrl = () => {
  const tenant =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const fetchHealthMailLoginUrl = async () => {
    const axiosInstance = createAxiosInstance();
    const response = await axiosInstance.get(`azure/login/url/${tenant}`);

    return response.data;
  };

  return useQuery({
    queryKey: ["healthMailLoginUrl"],
    queryFn: fetchHealthMailLoginUrl,
    onError: (error) => {
      console.error("Error fetching health mail login url:", error);
    },
  });
};

export default useHealthMailLoginUrl;

import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import style from "./Table.module.css";

import { useQueryClient } from "@tanstack/react-query";
import useHealthMailContactsList from "../../../../../../../hooks/useHealthMailContactsList";
import { FormField } from "../../../../../../../components/form/FormField/FormField";
import useHealthMailSendPrescription from "../../../../../../../hooks/useHealthMailSendPrescription";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const formSchema = z.object({
  contactsIds: z
    .array(z.number())
    .min(1, "At least one contact must be selected"),
});

export const SendPrescriptionForm = ({
  onSuccess,
  prescriptionId,
  patientId,
}) => {
  const queryClient = useQueryClient();
  const sendPrescription = useHealthMailSendPrescription({});
  const { data: contacts } = useHealthMailContactsList();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      contactsIds: [],
    },
  });

  const handleSubmit = async (values) => {
    try {
      await sendPrescription.mutateAsync({
        prescriptionId,
        patientId,
        contactsIds: values.contactsIds,
      });
      onSuccess?.();

      queryClient.invalidateQueries();
    } catch (error) {
      console.error("Submission error:", error);
    }
  };

  return (
    <FormProvider {...form}>
      <form
        className={style.addLetterForm}
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormField name="contactsIds">
          <label className={style.addLetterFormLabel}>Select Contacts</label>
          <Autocomplete
            multiple
            options={contacts || []}
            getOptionLabel={(option) => option.name}
            sx={{
              "& .MuiOutlinedInput-root": {
                border: "none !important",
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-input": {
                padding: "0 10px !important",
                height: "36px !important",
                outline: "none !important",
              },
              "& .MuiOutlinedInput-root:focus": {
                border: "1px solid var(--gray-400)",
                outline: "none !important",
              },
            }}
            onChange={(_, newValue) => {
              form.setValue(
                "contactsIds",
                newValue.map((contact) => contact.id)
              );
            }}
            value={(contacts || []).filter((contact) =>
              form.watch("contactsIds")?.includes(contact.id)
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search contacts"
                error={!!form.formState.errors.contactsIds}
                helperText={form.formState.errors.contactsIds?.message}
              />
            )}
            fullWidth
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </FormField>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            type="button"
            onClick={form.handleSubmit(handleSubmit)}
            disabled={sendPrescription.isPending}
            className={style.sendButton}
          >
            {sendPrescription.isPending ? "Submitting..." : "Send"}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

import React from "react";

import Modal from "../../../../../components/helpers/Modal/Modal";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { format, getYear } from "date-fns";

import style from "./AddCDMReviewDialog.module.css";
import { FormField } from "../../../../../components/form/FormField/FormField";
import Select from "../../../../../components/helpers/Select/Select";
import { useStaffMembers } from "../../../../../hooks/useStaffMembers";
import CustomCheckbox from "../../../../../components/helpers/CustomCheckbox/CustomCheckbox";

const formSchema = z.object({
  eircode: z.string().min(1, { message: "Please provide eircode." }),
  ethnicity: z.string().min(1, { message: "Please provide ethnicity." }),
  reviewingDoctor: z
    .string()
    .min(1, { message: "Please provide reviewing doctor." }),
  myocardialInfarction: z.boolean().optional(),
  significantMentalIllness: z.boolean().optional(),
  dementia: z.boolean().optional(),
  hypertensionOnTreatment: z.boolean().optional(),
  cancer: z.boolean().optional(),
  infammatoryArthritis: z.boolean().optional(),
  seriousMobilityIssues: z.boolean().optional(),
  chronicKidneyDisease: z.boolean().optional(),
  other: z.boolean().optional(),
  otherText: z.string().optional(),

  diabetesType2: z.boolean().optional(),
  asthma: z.boolean().optional(),
  COPD: z.boolean().optional(),
  heartFailure: z.boolean().optional(),
  atrialFibrillation: z.boolean().optional(),
  ischaemicHeartDisease: z.boolean().optional(),
  cerebrovascularDiseaseStroke: z.boolean().optional(),
  cerebrovascularDiseaseTIA: z.boolean().optional(),

  diabetesType2AttendingHospital: z.boolean().optional(),
  asthmaAttendingHospital: z.boolean().optional(),
  COPDAttendingHospital: z.boolean().optional(),
  heartFailureAttendingHospital: z.boolean().optional(),
  atrialFibrillationAttendingHospital: z.boolean().optional(),
  ischaemicHeartDiseaseAttendingHospital: z.boolean().optional(),
  cerebrovascularDiseaseStrokeAttendingHospital: z.boolean().optional(),
  cerebrovascularDiseaseTIAAttendingHospital: z.boolean().optional(),

  diabetesType2Year: z.string().optional(),
  asthmaYear: z.string().optional(),
  COPDYear: z.string().optional(),
  heartFailureYear: z.string().optional(),
  atrialFibrillationYear: z.string().optional(),
  ischaemicHeartDiseaseYear: z.string().optional(),
  cerebrovascularDiseaseStrokeYear: z.string().optional(),
  cerebrovascularDiseaseTIAYear: z.string().optional(),
});

const diagnosisItems = [
  {
    id: "diabetesType2",
    name: "Diabetes Type 2",
  },
  {
    id: "asthma",
    name: "Asthma",
  },
  {
    id: "COPD",
    name: "COPD",
  },
  {
    id: "heartFailure",
    name: "Heart Failure",
  },
  {
    id: "atrialFibrillation",
    name: "Atrial Fibrillation",
  },
  {
    id: "ischaemicHeartDisease",
    name: "Ischaemic Heart Disease",
  },
  {
    id: "cerebrovascularDiseaseStroke",
    name: "Cerebrovascular Disease Stroke",
  },
  {
    id: "cerebrovascularDiseaseTIA",
    name: "Cerebrovascular Disease TIA",
  },
];

export const AddCDMReviewDialog = ({
  isAddCdmReviewDialogOpen,
  setIsAddCdmReviewDialogOpen,
}) => {
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      eircode: "",
      contractDoctor: "",
      ethnicity: "",
      myocardialInfarction: false,
      significantMentalIllness: false,
      dementia: false,
      hypertensionOnTreatment: false,
      cancer: false,
      infammatoryArthritis: false,
      seriousMobilityIssues: false,
      chronicKidneyDisease: false,
      other: false,
      otherText: "",
    },
  });

  const { data: staffMembers } = useStaffMembers();

  console.log(staffMembers);

  const handleFormSubmit = (data) => {
    console.log(data);
    setIsAddCdmReviewDialogOpen(false);
  };

  const importedData = {
    gmsNumber: "423423423",
    contractDoctor: "Dr. John Doe",
    contractDoctorHash: "545345345",
    patientDOB: "13/03/1980",
  };

  if (!isAddCdmReviewDialogOpen) {
    return null;
  }

  return (
    <Modal
      title="Add Review"
      position="center"
      onClose={() => setIsAddCdmReviewDialogOpen(false)}
      hideCancel={true}
      width="1100px"
      maxHeight="95vh"
    >
      <div style={{ overflowY: "auto", maxHeight: "calc(95vh - 140px)" }}>
        <div className={style.importedDataWrapper}>
          <div className={style.importedDataItem}>
            <p className={style.importedDataItemLabel}>GMS Number</p>
            <p className={style.importedDataItemValue}>
              {importedData.gmsNumber}
            </p>
          </div>
          <div className={style.importedDataItem}>
            <p className={style.importedDataItemLabel}>Contract Doctor</p>
            <p className={style.importedDataItemValue}>
              {importedData.contractDoctor}
            </p>
          </div>
          <div className={style.importedDataItem}>
            <p className={style.importedDataItemLabel}>Contract Doctor #</p>
            <p className={style.importedDataItemValue}>
              {importedData.contractDoctorHash}
            </p>
          </div>
          <div className={style.importedDataItem}>
            <p className={style.importedDataItemLabel}>Patient DOB</p>
            <p className={style.importedDataItemValue}>
              {importedData.patientDOB}
            </p>
          </div>
        </div>
        <FormProvider {...form}>
          <form
            className={style.addReferralForm}
            onSubmit={form.handleSubmit(handleFormSubmit)}
          >
            <div className={style.inputGroupGrid4Columns}>
              <div className={`${style.formField} form-field-controll`}>
                <div>
                  <label className={style.pregnancyFormLabel}>
                    Consultation date
                  </label>
                  <input
                    name="consultationDate"
                    disabled
                    type="text"
                    className="form-control"
                    value={format(new Date(), "dd/MM/yyyy")}
                  />
                </div>
              </div>
              <FormField name="contractDoctor">
                <label className={style.addReferralFormLabel}>
                  Contract Doctor
                </label>
                <Select
                  options={staffMembers.map((staff) => ({
                    value: staff.id,
                    label: `${staff.firstName} ${staff.lastName}`,
                  }))}
                  value={form.watch("reviewingDoctor") ?? ""}
                  onChange={(value) => form.setValue("reviewingDoctor", value)}
                  required
                />
              </FormField>
              <FormField name="ethnicity">
                <label className={style.addReferralFormLabel}>Ethnicity</label>
                <Select
                  options={[
                    "White Irish",
                    "White Other",
                    "Asian",
                    "Black",
                    "Other",
                  ]}
                  value={form.watch("ethnicity") ?? ""}
                  onChange={(value) => form.setValue("ethnicity", value)}
                  required
                />
              </FormField>
              <FormField name="eircode">
                <label className={style.pregnancyFormLabel}>Eircode</label>
                <input
                  type="text"
                  className="form-control"
                  {...form.register("eircode")}
                />
              </FormField>
            </div>
            <div className={style.indicatorsSection}>
              <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>
                Diagnosis
              </h2>
              <div className={style.tableContainer}>
                <table className={style.table}>
                  <thead className={style.thead}>
                    <tr>
                      <th className={style.th}></th>
                      <th className={style.th}>Diagnosis</th>
                      <th className={style.th}>Status</th>
                      <th className={style.th}>Last Review</th>
                      <th className={style.th}>Year</th>
                      <th className={style.th}>Attending Hospital</th>
                    </tr>
                  </thead>
                  <tbody>
                    {diagnosisItems.map((item) => (
                      <tr key={item.id}>
                        <td
                          className={style.tdName}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "40px",
                          }}
                        >
                          <CustomCheckbox {...form.register(item.id)} />
                        </td>
                        <td
                          title="Diagnosis"
                          className={style.tdName}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "300px",
                          }}
                        >
                          {item.name}
                        </td>
                        <td
                          title="Status"
                          className={style.tdName}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "120px",
                          }}
                        ></td>
                        <td
                          title="Last Review"
                          className={style.tdName}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "120px",
                          }}
                        ></td>
                        <td
                          title="Year"
                          className={style.tdName}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "140px",
                          }}
                        >
                          <Select
                            small
                            options={Array.from(
                              { length: 150 },
                              (_, i) => getYear(new Date()) - i
                            ).map((year) => ({
                              value: year,
                              label: year,
                            }))}
                            disabled={!form.watch(item.id)}
                            value={form.watch(`${item.id}Year`) ?? ""}
                            onChange={(value) =>
                              form.setValue(`${item.id}Year`, value)
                            }
                            required
                          />
                        </td>
                        <td
                          title="Attending Hospital"
                          className={style.tdName}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "200px",
                          }}
                        >
                          <CustomCheckbox
                            {...form.register(`${item.id}AttendingHospital`)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className={style.indicatorsSection}>
              <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>
                Indicators for Review
              </h2>
              <fieldset className={style.medsCheckboxGroup}>
                <div className={style.inputGroupGrid}>
                  <label className={style.pregnancyFormCheckboxLabel}>
                    <CustomCheckbox
                      {...form.register("myocardialInfarction")}
                    />
                    Myocardial Infarction
                  </label>
                  <label className={style.pregnancyFormCheckboxLabel}>
                    <CustomCheckbox
                      {...form.register("significantMentalIllness")}
                    />
                    Significant Mental Illness (current)
                  </label>
                  <label className={style.pregnancyFormCheckboxLabel}>
                    <CustomCheckbox {...form.register("dementia")} />
                    Dementia
                  </label>
                  <label className={style.pregnancyFormCheckboxLabel}>
                    <CustomCheckbox
                      {...form.register("hypertensionOnTreatment")}
                    />
                    Hypertenstion (On Treatment)
                  </label>
                  <label className={style.pregnancyFormCheckboxLabel}>
                    <CustomCheckbox {...form.register("cancer")} />
                    Cancer (Stage 2 or higher)
                  </label>
                  <label className={style.pregnancyFormCheckboxLabel}>
                    <CustomCheckbox
                      {...form.register("infammatoryArthritis")}
                    />
                    Infammatory Arthritis
                  </label>
                  <label className={style.pregnancyFormCheckboxLabel}>
                    <CustomCheckbox
                      {...form.register("seriousMobilityIssues")}
                    />
                    Serious Mobility Issues
                  </label>
                  <label className={style.pregnancyFormCheckboxLabel}>
                    <CustomCheckbox
                      {...form.register("chronicKidneyDisease")}
                    />
                    Chronic Kidney Disease
                  </label>
                  <label className={style.pregnancyFormCheckboxLabel}>
                    <CustomCheckbox {...form.register("other")} />
                    Other
                  </label>
                </div>
              </fieldset>
              <FormField name="otherText">
                <textarea
                  className="form-control textareaform-control"
                  disabled={!form.watch("other")}
                  style={{ minHeight: "100px", marginTop: "16px" }}
                  {...form.register("otherText")}
                />
              </FormField>
            </div>
            <div className={style.bottomSection}>
              <button
                type="button"
                className={style.cancelButton}
                onClick={() => setIsAddCdmReviewDialogOpen(false)}
              >
                Cancel
              </button>
              <button type="submit" className={style.sendButton}>
                Confirm
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

import { useState } from "react";

export const views = {
  noChronicDiseases: "noChronicDiseases",
  general: "general",
};

export const useView = () => {
  const [view, setView] = useState({
    name: views.noChronicDiseases,
    cdmId: null,
  });

  return [view, setView];
};

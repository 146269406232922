import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { createAxiosInstance } from "../api/axiosConfig";

const useFetchHealthMailLoginDetails = () => {
  const tenant =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const fetchHealthMailLoginDetails = async () => {
    const axiosInstance = createAxiosInstance();
    const response = await axiosInstance.get(`${tenant}/healthmailcredentials`);

    return response.data;
  };

  return useQuery({
    queryKey: ["healthMailLoginDetails"],
    queryFn: fetchHealthMailLoginDetails,
    onError: (error) => {
      console.error("Error fetching health mail login details:", error);
    },
  });
};

export default useFetchHealthMailLoginDetails;

import React from "react";

import Modal from "../../../../../components/helpers/Modal/Modal";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { format } from "date-fns";

import style from "./AddOCFReviewDialog.module.css";
import { FormField } from "../../../../../components/form/FormField/FormField";
import Select from "../../../../../components/helpers/Select/Select";
import { useStaffMembers } from "../../../../../hooks/useStaffMembers";
import CustomCheckbox from "../../../../../components/helpers/CustomCheckbox/CustomCheckbox";

const formSchema = z.object({
  eircode: z.string().min(1, { message: "Please provide eircode." }),
  ethnicity: z.string().min(1, { message: "Please provide ethnicity." }),
  reviewingDoctor: z
    .string()
    .min(1, { message: "Please provide reviewing doctor." }),
  other: z.boolean().optional(),
  otherText: z.string().optional(),
  hypertension: z.boolean().optional(),
  currentSmokingStatus: z.boolean().optional(),
  bmi: z.boolean().optional(),
  previousBNP: z.boolean().optional(),
  historyOfGestationalDiabetes: z.boolean().optional(),
  dyslipidsemia: z.boolean().optional(),
  moderateOrSevereChronicKidneyDisease: z.boolean().optional(),
});

export const AddOCFReviewDialog = ({
  isAddOcfReviewDialogOpen,
  setIsAddOcfReviewDialogOpen,
}) => {
  const { data: staffMembers } = useStaffMembers();
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      eircode: "",
      contractDoctor: "",
      ethnicity: "",
      other: false,
      otherText: "",
      hypertension: false,
      currentSmokingStatus: false,
      bmi: false,
      previousBNP: false,
      historyOfGestationalDiabetes: false,
      dyslipidsemia: false,
      moderateOrSevereChronicKidneyDisease: false,
    },
  });

  const handleFormSubmit = (data) => {
    console.log(data);
    setIsAddOcfReviewDialogOpen(false);
  };

  const importedData = {
    gmsNumber: "423423423",
    contractDoctor: "Dr. John Doe",
    contractDoctorHash: "545345345",
    patientDOB: "13/03/1980",
  };

  if (!isAddOcfReviewDialogOpen) {
    return null;
  }

  return (
    <Modal
      title="Add Review"
      position="center"
      onClose={() => setIsAddOcfReviewDialogOpen(false)}
      hideCancel={true}
      width="1100px"
      maxHeight="95vh"
    >
      <div style={{ overflowY: "auto", maxHeight: "calc(95vh - 140px)" }}>
        <div className={style.importedDataWrapper}>
          <div className={style.importedDataItem}>
            <p className={style.importedDataItemLabel}>GMS Number</p>
            <p className={style.importedDataItemValue}>
              {importedData.gmsNumber}
            </p>
          </div>
          <div className={style.importedDataItem}>
            <p className={style.importedDataItemLabel}>Contract Doctor</p>
            <p className={style.importedDataItemValue}>
              {importedData.contractDoctor}
            </p>
          </div>
          <div className={style.importedDataItem}>
            <p className={style.importedDataItemLabel}>Contract Doctor #</p>
            <p className={style.importedDataItemValue}>
              {importedData.contractDoctorHash}
            </p>
          </div>
          <div className={style.importedDataItem}>
            <p className={style.importedDataItemLabel}>Patient DOB</p>
            <p className={style.importedDataItemValue}>
              {importedData.patientDOB}
            </p>
          </div>
        </div>
        <FormProvider {...form}>
          <form
            className={style.addReferralForm}
            onSubmit={form.handleSubmit(handleFormSubmit)}
          >
            <div className={style.inputGroupGrid4Columns}>
              <div className={`${style.formField} form-field-controll`}>
                <div>
                  <label className={style.pregnancyFormLabel}>
                    Consultation date
                  </label>
                  <input
                    name="consultationDate"
                    disabled
                    type="text"
                    className="form-control"
                    value={format(new Date(), "dd/MM/yyyy")}
                  />
                </div>
              </div>
              <FormField name="contractDoctor">
                <label className={style.addReferralFormLabel}>
                  Contract Doctor
                </label>
                <Select
                  options={staffMembers.map((staff) => ({
                    value: staff.id,
                    label: `${staff.firstName} ${staff.lastName}`,
                  }))}
                  value={form.watch("reviewingDoctor") ?? ""}
                  onChange={(value) => form.setValue("reviewingDoctor", value)}
                  required
                />
              </FormField>
              <FormField name="ethnicity">
                <label className={style.addReferralFormLabel}>Ethnicity</label>
                <Select
                  options={[
                    "White Irish",
                    "White Other",
                    "Asian",
                    "Black",
                    "Other",
                  ]}
                  value={form.watch("ethnicity") ?? ""}
                  onChange={(value) => form.setValue("ethnicity", value)}
                  required
                />
              </FormField>
              <FormField name="eircode">
                <label className={style.pregnancyFormLabel}>Eircode</label>
                <input
                  type="text"
                  className="form-control"
                  {...form.register("eircode")}
                />
              </FormField>
            </div>
            <div className={style.indicatorsSection}>
              <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>
                Indicators for Review
              </h2>
              <fieldset className={style.medsCheckboxGroup}>
                <div className={style.checkboxWrapper}>
                  <label className={style.pregnancyFormCheckboxLabel}>
                    <CustomCheckbox {...form.register("hypertension")} />
                    {"Hypertension <=140/90mmHg"}
                  </label>
                  <label className={style.pregnancyFormCheckboxLabel}>
                    <CustomCheckbox
                      {...form.register("currentSmokingStatus")}
                    />
                    Current Smoking Status
                  </label>
                  <label className={style.pregnancyFormCheckboxLabel}>
                    <CustomCheckbox {...form.register("bmi")} />
                    {"BMI >=30kg/m2"}
                  </label>
                  <label className={style.pregnancyFormCheckboxLabel}>
                    <CustomCheckbox {...form.register("previousBNP")} />
                    {"Previous BNP >=34pg/ml or NTproBNP >=125pg/ml"}
                  </label>
                  <label className={style.pregnancyFormCheckboxLabel}>
                    <CustomCheckbox
                      {...form.register("historyOfGestationalDiabetes")}
                    />
                    {"History of Gestational Diabetes"}
                  </label>
                  <label className={style.pregnancyFormCheckboxLabel}>
                    <CustomCheckbox {...form.register("dyslipidsemia")} />
                    {"Dysplipidsemia (previously recorded)"}
                  </label>
                  <label className={style.pregnancyFormCheckboxLabel}>
                    <CustomCheckbox
                      {...form.register("moderateOrSevereChronicKidneyDisease")}
                    />
                    {
                      "Moderate or severe chronic kidney disease (eGFR <60ml/min 1.73m2 previously recorded)"
                    }
                  </label>
                  <label className={style.pregnancyFormCheckboxLabel}>
                    <CustomCheckbox
                      {...form.register("historyOfSevereMentalIllness")}
                    />
                    History of severe Mental Illness
                  </label>
                  <label className={style.pregnancyFormCheckboxLabel}>
                    <CustomCheckbox {...form.register("other")} />
                    Other
                  </label>
                </div>
              </fieldset>
              <FormField name="otherText">
                <textarea
                  className="form-control textareaform-control"
                  disabled={!form.watch("other")}
                  style={{ minHeight: "100px", marginTop: "16px" }}
                  {...form.register("otherText")}
                />
              </FormField>
            </div>
            <div className={style.bottomSection}>
              <button
                type="button"
                className={style.cancelButton}
                onClick={() => setIsAddOcfReviewDialogOpen(false)}
              >
                Cancel
              </button>
              <button type="submit" className={style.sendButton}>
                Confirm
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { createAxiosInstance } from "../api/axiosConfig";

export const useHealthMailContactsList = () => {
  const tenant =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const fetchHealthMailContactsList = async () => {
    const axiosInstance = createAxiosInstance();
    const response = await axiosInstance.get(`${tenant}/healthmailcontacts`);

    return response.data;
  };

  return useQuery({
    queryKey: ["healthMailContactsList"],
    queryFn: fetchHealthMailContactsList,
    onError: (error) => {
      console.error("Error fetching health mail contacts list:", error);
    },
  });
};

export default useHealthMailContactsList;
